import { Box, Center, HStack, Image, Input, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import GreenCheckmarkSVG from "../../images/green_checkmark.svg";
import SmallCloseIconSVG from "../../images/small_close_icon.svg";
import {
  OpaliteButtonInput,
  OpaliteButtonStandard,
} from "../shared/opalite_buttons";
import BackgroundGradient from "../../images/background_slim_gradient.svg";
import { navigate } from "gatsby";

interface SuccessProps {}

const Success: React.FC<SuccessProps> = (props) => {
  const [toggleMintingProgressDisplay, setToggleMintingProgressDisplay] =
    useState(true);
  const [collectionName, setCollectionName] = useState("collection");

  return (
    <Box
      paddingTop="5rem"
      paddingInline="2rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
    >
      <Center
        justifyContent="space-between"
        borderRadius="5px"
        padding=".5rem"
        backgroundColor={COLORS_PRIMARY.MINTING_IN_PROCESS_BACKGROUND}
        display={toggleMintingProgressDisplay ? "flex" : "none"}
        width={{ base: "80%", lg: "50%" }}
        position="fixed"
        left={{ base: "10%", lg: "25%" }}
      >
        <HStack>
          <Image justifyContent="space-between" src={GreenCheckmarkSVG} />
          <OpaliteTextStandard color={COLORS_PRIMARY.BACKGROUND}>
            Solflare connected
          </OpaliteTextStandard>
        </HStack>
        <Image
          onClick={() => setToggleMintingProgressDisplay(false)}
          src={SmallCloseIconSVG}
        />
      </Center>
      <Center
        flexDirection="column"
        height="100%"
        alignContent="center"
        textAlign="center"
        marginTop="-4rem"
      >
        <OpaliteTextTitle marginBottom="1rem">
          Your {collectionName} has been created!
        </OpaliteTextTitle>
        <OpaliteTextStandard>
          Your NFTs will be purchasable on the Opalite storefront shortly!
        </OpaliteTextStandard>
      </Center>
      <Box
        paddingBottom="2rem"
        width={{ base: "80%", lg: "50%" }}
        position="fixed"
        left={{ base: "10%", lg: "25%" }}
        bottom="0"
      >
        <OpaliteButtonStandard
          width="100%"
          onClick={() => navigate("/all_nft")}
        >
          View Storefront
        </OpaliteButtonStandard>
      </Box>
    </Box>
  );
};

export default Success;
