import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import Success from "../components/success/success";

function LoadingPage() {
  return (
    <ChakraProvider>
      <Header />
      <Success />
    </ChakraProvider>
  );
}

export default LoadingPage;
